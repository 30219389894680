import type { RouteKey } from 'coreSrc/base/systemManager/routeManager';

import createRichgoMapPath from 'hostSrc/pages/realty/richgoMap/createRichgoMapPath';

export type GnbItem = {
  key: RouteKey;
  href: string;
  label: string;
  icon: string;
  activeIcon: string;
  slug?: Record<string, string>;
  hideSamsung?: boolean;
  samsungOnly?: boolean;
  hideNh?: boolean;
  hideZum?: boolean;
  pathMatcher?: (pathname: string) => boolean;
  onlyDev?: boolean;
};

const gnbItems: GnbItem[] = [
  {
    key: 'myHouses',
    href: '/realty/home?tab=main',
    label: '홈',
    icon: 'gnb-home',
    activeIcon: 'gnb-home.darkgray',
    samsungOnly: true,
    hideNh: true,
    hideZum: true,
  },
  {
    key: 'home',
    href: '/realty/home?tab=main',
    label: '홈',
    icon: 'gnb-home',
    activeIcon: 'gnb-home.darkgray',
    hideSamsung: true,
    hideNh: true,
    hideZum: false,
  },
  {
    key: 'map',
    href: createRichgoMapPath(),
    label: '아파트',
    icon: 'gnb-map',
    activeIcon: 'gnb-map.darkgray',
    hideNh: false,
    hideZum: false,
  },

  {
    key: 'landMap',
    href: '/landMap',
    label: '토지건물',
    icon: 'gnb-land-map',
    activeIcon: 'gnb-land-map.darkgray',
    hideNh: false,
    hideZum: false,
    // onlyDev: true,
  },
  {
    key: 'auction',
    href: '/auction',
    label: '경매',
    icon: 'gnb-auction',
    activeIcon: 'gnb-auction.darkgray',
    hideZum: true,
    // samsungOnly: true,
  },
];

export default gnbItems;
