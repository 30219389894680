import { createGlobalStyle, css } from 'styled-components';

const GlobalStyleSheet = createGlobalStyle<{
  isPc: boolean;
  isIOS: boolean;
  isMobile: boolean;
}>`
  html, body, #__next {
    padding: 0;
    height: 100%;
    //min-height: 100vh;
    width: 100%;
    margin: 0;
    
  }

  img {
    line-height: 1;
  }

  body {
    ${(p) =>
      p.isIOS &&
      css`
        position: fixed;
        overflow: hidden;
      `};
    overscroll-behavior: contain;
  }

  #__next {
    position: static;
    //min-height: 100vh;
    overflow-x: hidden;

    ${(p) =>
      p.isIOS &&
      css`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      `};
  }

  body.noscroll {
    overflow-y: hidden;
  }

  * {
    print-color-adjust: exact !important;

    font-family: "SUIT Variable", sans-serif;
    position: relative;
    box-sizing: border-box;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    outline: none;
  }

  input, textarea, *[contenteditable=true] {
    -webkit-user-select: auto;
    user-select: auto;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  button {
    padding: 0;
    outline: none;
    border: 0;
    margin: 0;
    cursor: pointer;
  }


  .markerWindow{
    border-radius: 10px;
    background: #fff;
    border : 1px solid #aaa;
    box-shadow: 0 0 10px rgba(0,0,0,0.16);
    line-height: 1;
    font-size: 12px;
    animation: pinIntro;
    animation-duration: 0.26s;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    
  }
  .markerWindow > * {
    white-space: nowrap;
  }
  @keyframes pinIntro {
    from {
      opacity: 0;
      transform: scale(0)
    }
    to {
      opacity: 1;
      transform: scale(1)
    }
  }
  @keyframes pinIntroV3 {
    from {
      opacity: 0;
      transform: scale(0)  translate3d(-50%,50%,0)
    }
    to {
      opacity: 1;
      transform: scale(1)  translate3d(-50%,50%,0)
    }
  }
  @keyframes geoMarkerIntro {
    from {
      opacity: 0;
      transform: translate(-50%,-50%) scale(0)
    }
    to {
      opacity: 1;
      transform: translate(-50%,-50%) scale(1)
    }
  }

  @keyframes pcDetailIntro {
    from {
      opacity: 0;
      transform: translate(-50px, 0);
    }
    to {
      opacity: 1;
      transform: translate(0px, 0);
    }
  }

  @keyframes clusterIntro {
    0% {
      opacity: 0;
      transform: scale(0)
    }
    //35% {
    //  opacity: 0;
    //  transform: scale(0)
    //}
    100% {
      opacity: 1;
      transform: scale(1)
    }
  }
  

  @keyframes rotationAni {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  @keyframes previewIntro {
    from {
      bottom: -150px
    }
    to {
      bottom: 0;
    }
  }

  @keyframes popupIntro {
    from {
      bottom: -150px
    }
    to {
      bottom: 0px
    }
  }


 

  ${(p) =>
    p.isMobile
      ? ''
      : `
 ::-webkit-scrollbar { width: 7px; height: 5.2px; } /* 스크롤 바 */

  ::-webkit-scrollbar-track {background-color:#fff; border-left: 1px solid #E8EAEA ; border-right: 1px solid #E8EAEA }

  ::-webkit-scrollbar-thumb { background: rgb(134 151 178 / 50%); border-radius: 3.5px; } /* 실질적 스크롤 바 */

  ::-webkit-scrollbar-thumb:hover { background: #5e7ade; } /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */

  ::-webkit-scrollbar-thumb:active { background: #5e7ade; } /* 실질적 스크롤 바를 클릭할 때 */

  ::-webkit-scrollbar-button { display: none; } /* 스크롤 바 상 하단 버튼 */
 `}
  .pc_scroll::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }

  /* 스크롤 바 */

  .pc_scroll::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #E8EAEA;
    border-right: 1px solid #E8EAEA
  }

  /* 스크롤 바 밑의 배경 */

  .pc_scroll::-webkit-scrollbar-thumb {
    background: rgb(134 151 178 / 50%);
    border-radius: 3.5px;
  }

  /* 실질적 스크롤 바 */

  .pc_scroll::-webkit-scrollbar-thumb:hover {
    background: #5e7ade;
  }

  /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */

  .pc_scroll::-webkit-scrollbar-thumb:active {
    background: #5e7ade;
  }

  /* 실질적 스크롤 바를 클릭할 때 */

  .pc_scroll::-webkit-scrollbar-button {
    display: none;
  }

  /* 스크롤 바 상 하단 버튼 */

  .marker > div:first-child, .marker_hoje > div:first-child, .marker_parcel > div:first-child, .marker_cluster > div:first-child {
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.155, 1.465), filter 0.2s;
  }

  .marker_cluster > div:first-child {
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.155, 1.465);
  }

  .marker > div:first-child:hover {
    transform: translate(0, 0px) scale(1.1);
    filter: contrast(1.2);
  }

  .marker_hoje > div:first-child:hover {
    transform: translate(0, 0px) scale(1.1);
    filter: contrast(1.2);
  }

  .marker_cluster > div:first-child:hover {
    transform: translate(0, 0px) scale(0.95);
  }

  .marker_parcel > div:first-child:hover {
    transform: translate(5px, 0px) scale(1.15);
    filter: contrast(1.2);
  }

  .SellingPhotoSlider .carousel .control-prev.control-arrow:before {
    border: 0;
    width: 9px;
    height: 4px;
    z-index: 2;
    opacity: 1;
    content: url("data:image/svg+xml,<svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.5 1.5L1 6L5.5 10.5' stroke='white' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/> </svg>")
  }

  .SellingPhotoSlider .carousel .control-next.control-arrow:before {
    z-index: 2;
    border: 0;
    width: 9px;
    height: 4px;
    opacity: 1;
    content: url("data:image/svg+xml,<svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M1.5 1.5L6 6L1.5 10.5' stroke='white' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/> </svg> ");
    transform: translate(0,-7px);
  }
  
  .similarMarker {
    opacity: 0.5;
  }
  .similarMarker:hover{
    opacity: 1;
  }

  .grabbing {
    cursor: grabbing;
  }
  .grabbing * {
    cursor: grabbing;
  }

  :root {
    --System-White: #fff;
    --System-Gray: #31343D;
    --System-Black: #000;
    --System-Red: #FF0048;
    --System-RedTranslucent: rgba(255, 0, 72, 0.06);
    --System-Orange: #FF5400;
    --System-OrangeTranslucent: rgba(255, 84, 0, 0.06);
    --System-Yellow: #FFB000;
    --System-YellowTranslucent: rgba(255, 176, 0, 0.06);
    --System-Green: #00BC71;
    --System-GreenTranslucent: rgba(0, 188, 113, 0.06);
    --System-Blue: #0087FF;
    --System-BlueTranslucent: rgba(0, 135, 255, 0.06);
    --System-Indigo: #5856E6;
    --System-IndigoTranslucent: rgba(88, 86, 230, 0.06);

    --Core-Primary: var(--System-Blue, #0087FF);
    --Core-Secondary: rgba(0, 135, 255, 0.06);
    --Core-NeutralPrimary: #30343F;
    --Core-NeutralSecondary: #FFF;

    --Background-Primary: #FFF;
    --Background-Secondary: #F3F7FA;
    --Background-Tertiary: #E9EEF6;

    --Label-Primary: #21232B;
    --Label-Secondary: rgba(33, 35, 43, 0.60);
    --Label-Tertiary: rgba(33, 35, 43, 0.32);
    --Label-Quarternary: rgba(33, 35, 43, 0.16);

    --Separator-Primary: rgba(100, 129, 158, 0.20);
    --Separator-Secondary: rgba(100, 129, 158, 0.12);
    --Separator-Tertiary: rgba(100, 129, 158, 0.08);

    --Group-Primary: #FFF;
    --Group-Secondary: rgba(255, 255, 255, 0.60);
    --Group-Tertiary: rgba(255, 255, 255, 0.32);
    --Group-Quarternary: rgba(255, 255, 255, 0.16);

    --Fill-Primary: rgba(100, 129, 158, 0.06);
    --Fill-Secondary: rgba(100, 129, 158, 0.08);
    --Fill-Tertiary: rgba(100, 129, 158, 0.12);

    --Radius-None: 0px;
    --Radius-XXSmall: 2px;
    --Radius-XSmall: 4px;
    --Radius-Small: 6px;
    --Radius-Medium: 8px;
    --Radius-Large: 12px;
    --Radius-XLarge: 16px;
    --Radius-XXLarge: 20px;
    --Radius-Full: 999px;
  }

  /* storybook */
  #storybook-docs {
    overflow: auto;
    max-height: 100vh;
  }
  .auction_info img{
    pointer-events: none;
  }
`;

export default GlobalStyleSheet;
