const CONST_NAVER_MAP_MODE = {
  RICHGO_MAP_PC_V1: 'RICHGO_MAP_PC_V1',
  RICHGO_MAP_PC_GRADE: 'RICHGO_MAP_PC_GRADE',
  RICHGO_MAP_V1: 'RICHGO_MAP_V1',
  RICHGO_SEARCH_PC_HOME: 'RICHGO_SEARCH_PC_HOME',
  RICHGO_SEARCH_BY_EDUCATION: 'RICHGO_SEARCH_BY_EDUCATION',
  RICHGO_SEARCH_BY_RE: 'RICHGO_SEARCH_BY_RE',
  RICHGO_SEARCH_BY_PUBLISH: 'RICHGO_SEARCH_BY_PUBLISH',
  RICHGO_SEARCH_BY_GAP: 'RICHGO_SEARCH_BY_GAP',
  RICHGO_SEARCH_BY_DISTANCE: 'RICHGO_SEARCH_BY_DISTANCE',
  LAND_V3: 'LAND_V3',
  AUCTION_SEARCH_V2: 'AUCTION_SEARCH_V2',
};

export default CONST_NAVER_MAP_MODE;
