import React from 'react';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import { APP_ENV } from 'coreSrc/core/js/env';

import Icon from '../../../../../hostSrc/comp/Icon';
import SignInDrawer from '../../../../../jhSrc/components/Drawer/SignInDrawer';
import useUserSystem from '../../../../base/userSystemManager/user/useUserSystem';
import gnbItems, { GnbItem } from './gnbItems';

import styled from 'styled-components';

function GNB({ visible = true }) {
  const sys = useSystem();
  const isIOS = sys.detector.isIOS;
  const isSamsung = sys.partner.getType() === 'samsung';
  const isNh = sys.partner.getType() === 'nh';

  const userSys = useUserSystem();
  const isLoggedIn = userSys.auth.getIsLoggedIn();
  const isActive = (item: GnbItem) => {
    if (isSamsung) {
      return sys.router.nowOn(item.key === 'home' ? 'myHouses' : item.key);
    }

    return item.pathMatcher?.(sys.router.pathname) ?? sys.router.nowOn(item.key);
  };

  function onItemClick(e: React.MouseEvent<HTMLAnchorElement>, item: GnbItem) {
    e.preventDefault();

    if (isActive(item)) return;

    if (item.key === 'signal' && !isLoggedIn) {
      return sys.dialog.open(<SignInDrawer />);
    } else {
      sys.router.go(item.key, item.slug);
    }
  }
  const devMode = ['develop', 'local'].includes(APP_ENV);
  const gnbList = [...gnbItems];

  return (
    <SD_Container style={{ height: visible ? `${GNB.HEIGHT}px` : 0, opacity: visible ? 1 : 0 }}>
      {/*{RICHGO_API_HOST}*/}
      {gnbList.map((item) => {
        if (isSamsung && item.hideSamsung) return null;
        if (isNh && isIOS && item.hideNh) return null;
        if (item.samsungOnly && !isSamsung) return null;
        if (item.samsungOnly && !isSamsung) return null;
        if (item.onlyDev && !devMode) return null;

        const active = isActive(item);
        const iconName = active ? item.activeIcon : item.icon;

        return (
          <SD_GnbTab
            key={item.key}
            href={item.href}
            active={active}
            onClick={(e) => onItemClick(e, item)}
          >
            <Icon
              name={iconName}
              width="24px"
              height="24px"
            />

            <span className="label">
              {' '}
              {item.label === '부동산시그널' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '3px',
                  }}
                >
                  {item.label}
                  <svg
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      transform: 'translate(calc(100% + 2px),-18px)',
                    }}
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="11"
                      height="11"
                      rx="3.23529"
                      fill="#FC3059"
                    />
                    <path
                      d="M2.76758 9.04134H4.1096V5.35447L7.11071 8.9676H8.23152V2.95801H6.8895V6.64488L3.88839 3.03175H2.76758V9.04134Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                item.label
              )}
            </span>
          </SD_GnbTab>
        );
      })}
      {isSamsung && (
        <SD_GnbTab2
          onClick={(e) => {
            sys.router.goOut(
              sys.detector.isIOS
                ? 'https://apps.apple.com/kr/app/%EB%B6%80%EB%8F%99%EC%82%B0-%EB%A6%AC%EC%B9%98%EA%B3%A0-%EC%95%84%ED%8C%8C%ED%8A%B8-%EC%8B%A4%EA%B1%B0%EB%9E%98%EA%B0%80%EC%99%80-%EB%B6%80%EB%8F%99%EC%82%B0-%EB%B9%85%EB%8D%B0%EC%9D%B4%ED%84%B0/id1540692940'
                : 'https://play.google.com/store/apps/details?id=com.dataknows_ai&hl=ko'
            );
          }}
        >
          <img
            src={'https://public-resources.richgo.ai/appIcon/리치고.png'}
            height={20}
          />
          리치고 이용
        </SD_GnbTab2>
      )}
    </SD_Container>
  );
}

/**
 * GNB 높이설정이며...
 * 이녀석이 DrawerContents 계산과 연계되서 빼두었음
 */
GNB.HEIGHT = 60;

export default GNB;

const SD_Container = styled.nav`
  overflow: hidden;

  transition: height 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.2s;

  z-index: 1;

  display: flex;
  flex-direction: row;

  background-color: #fff;

  border-top: 1px solid #e9eaec;
`;

const SD_GnbTab = styled.a<{ active: boolean }>`
  cursor: pointer;

  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  text-decoration: none;

  & > .label {
    color: ${({ active }) => (active ? '#333A46' : 'rgba(139, 151, 170, 1)')};

    font-size: 10px;
    font-weight: ${({ active }) => (active ? '500' : '400')};
  }
`;
const SD_GnbTab2 = styled.div`
  cursor: pointer;

  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(100% - 12px);

  text-decoration: none;

  font-size: 10px;
  font-weight: 500;
  background: #3182f6;
  color: #fff;
  border-radius: 8px;
  margin: 6px 6px 6px 0;
`;
