import { LogEventCategory, LogEventParameters } from 'coreSrc/core/js/utils/analytics/logEvent';

import { TradeType } from 'hostSrc/apis/model';

import CONST_NAVER_MAP_MODE from '../../../../../mapTestSrc/core/consts/CONST_NAVER_MAP_MODE';
import { APP_ENV } from '../../env';
import { ButtonClickMap, ButtonClickType, ScreenViewTypeEnum } from './consts';
import log from './log';

async function logSessionStart() {
  await log('session_start');
}

const getMapMode = (modeData) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const { uiMapState } = modeData || {};
  console.log('uiMapState.markerVisible', modeData);
  const mapMode = modeData
    ? (modeData?.v2_anlyticeYn
        ? '지역분석'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_DISTANCE
        ? '찾아줘_출퇴근'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_EDUCATION
        ? '찾아줘_학군'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_RE
        ? '찾아줘_재건축'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_GAP
        ? '찾아줘_갭'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_PUBLISH
        ? '찾아줘_공시지가'
        : uiMapState?.markerVisible?.auction
        ? '경매'
        : uiMapState?.markerVisible?.building || uiMapState?.markerVisible?.land
        ? '토지_건물'
        : '아파트') + '_mode'
    : 'None';
  const subMapMode = mapMode === '지역분석_mode' ? modeData?.uiMapState?.v3MapMode : undefined;
  return {
    mapMode,
    subMapMode,
  };
};
const getEnv = () => {
  return {
    appYn: !!globalThis?.richgoWebview,
    APP_ENV,
    referrer: document?.referrer || '',
  };
};

const parseSys = (sys) => {
  const pcYn = !sys.detector.isMobile;
  const partner = sys.partner ? (sys.partner.getType() === 'none' ? '' : sys.partner.getType()) : '';
  return { pcYn, partner };
};
async function v2_logGnbMobile(type: string, value: any, sys: { detector }, asPath) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_Gnb_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    from: asPath,
    ...getEnv(),
  });
}
async function v2_logMorePage(type: string, value: any, sys: { detector }, asPath) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_MorePage_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    from: asPath,
    ...getEnv(),
  });
}
async function v2_logMorePageDashBoard(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_MorePageDashBoard_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}
async function v2_logGnb(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Gnb_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}
async function v2_logParcelTableView(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_ParcelTableView_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}
// 주요 화면 뷰
export async function v2_logSceneView(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_SceneView_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}

async function v2_logAiPredict(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_AiPredict_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}
async function v2_logDanjiDetail(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_DanjiDetail_prototype_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}
async function v2_logGoRichgo(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_GoRichgo_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}
async function v2_logAppDown(type: string, value: any, sys: { detector }) {
  const { pcYn, partner } = parseSys(sys);
  await log(`v2_log_AppDown_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    ...getEnv(),
  });
}
async function v2_logAnalysisMapMode(type: string, value: any, sys: { detector }, modeData?) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_AnalysisMapMode_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logMarker(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Marker_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logToggleMarkerClick(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_MarkerVisible_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logFilter(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Filter_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logFloating(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Floating_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logBjdSelect(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_BjdSelect_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logRankingBoard(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_RankingBoard_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}
async function v2_logHedge(type: string, value: any, sys: { detector }, modeData = undefined) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = modeData
    ? getMapMode(modeData)
    : { mapMode: null, subMapMode: null };
  await log(`v2_log_Hedge_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logAdMarker(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = modeData
    ? getMapMode(modeData)
    : { mapMode: null, subMapMode: null };
  await log(`v2_log_AdMarker_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logAuctionGnb(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_AuctionGnb_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logAlarm(type: string, value: any, sys: { detector }, modeData) {
  const { pcYn, partner } = parseSys(sys);
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Alarm_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    partner,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

///////////////////////////////////////////////////////////////////////////////////

/** 중개플랫폼 신규작업 */
async function logButtonClick(type: ButtonClickType, value?: string) {
  await log('button_click', {
    id: ButtonClickMap[type],
    value,
  });
}

async function logNaverOpengoodsOutlinkClick(payload: {
  danjiId: string;
  opengoodsId: number;
  no: number;
  totalNo: number;
}) {
  await log('naver_opengoods_outlink_click', payload);
}

async function logNaverOpengoodsBottomSheetViewed(payload: {
  danjiId: string;
  opengoodsId: number;
  no: number;
  totalNo: number;
}) {
  await log('naver_opengoods_bottomsheet_viewed', payload);
}

async function logMinOfferPriceAnalyticsOpen(payload: {
  danjiId: string;
  from: 'chart' | 'min-offer';
  pyeongType: number;
  tradeType: TradeType;
}) {
  await log('min_offer_price_analytics_open', payload);
}

type Params<Key extends keyof typeof LogEventCategory> = Key extends keyof LogEventParameters
  ? LogEventParameters[Key]
  : undefined;
type LogEventParameterType<T extends keyof typeof LogEventCategory> = Params<T> extends undefined
  ? [name: T]
  : [name: T, params: Params<T>];

async function logEvent<T extends keyof typeof LogEventCategory>(...args: LogEventParameterType<T>) {
  const [name, meta] = args;
  try {
    await log(name, ...(meta ? [meta] : []));
  } catch (error) {
    console.log('logEvent error', error);
  }
}

const analytics = {
  Enum: {
    screenViewType: ScreenViewTypeEnum,
  },
  log,
  logSessionStart,
  ...{
    // 여기는 정리된 로그만 기록
    getMapMode,
    v2_logGnb, // GNB
    v2_logGnbMobile, // 모바일 GNB
    v2_logMorePage, // 더보기 페이지
    v2_logMorePageDashBoard, // 더보기 페이지
    v2_logToggleMarkerClick, // 마커 토글
    v2_logFilter, // 필터
    v2_logFloating, // 플로팅메뉴
    v2_logMarker, //마커
    v2_logBjdSelect, //지역선택기
    v2_logRankingBoard, //랭킹보드
    v2_logAdMarker, //지도내 광고마커
    v2_logAnalysisMapMode, // 지역분석모드
    v2_logAuctionGnb, // 경매gnb
    v2_logAlarm,

    v2_logAppDown, // 앱다운
    v2_logAiPredict, // AI 미래가격
    v2_logDanjiDetail, // 단지상세 테스트중

    v2_logParcelTableView, // 청약상세 테이블
    v2_logHedge, // 헷지했지 테이블
    v2_logGoRichgo, // 리치고 바로가기클릭
  },

  ...{
    logNaverOpengoodsOutlinkClick,

    logNaverOpengoodsBottomSheetViewed,
  },
  logMinOfferPriceAnalyticsOpen,
  logButtonClick,
  logEvent,
};
analytics.logSessionStart();
export default analytics;
