import { ReactElement, ReactNode } from 'react';

import dynamic from 'next/dynamic';

type Route = {
  path?: string;
  searchParams?: Record<string, string>;
};
type PopupRoute = Route & {
  Comp: ReactNode | ReactElement;
  disableOverlayEvent?: boolean;
  overlayColor?: string;
};
const pages = {
  ////
  brokerAdmin: {
    path: '/brokerAdmin',
  },
  brokerPay: {
    path: '/brokerPay',
  },
  brokerPayCheck: {
    path: '/purchase/brokerPayCheck',
  },
  brokerPromotion: {
    path: '/brokerAdmin/promotion',
  },
  brokerExclusiveSelectList: {
    path: '/brokerAdmin/exclusiveSelectList',
  },

  brokerNews: {
    path: '/brokerAdmin/news',
  },
  brokerProfile: {
    path: '/brokerAdmin/profile/[id]',
  },
  tossWidgetBrokerMonopoly: {
    path: '/brokerAdmin/brokerPayMonopoly/:productId',
  },
  ////
  index: {
    path: '/realty/richgoMap',
  },
  aptMap: {
    path: '/realty/richgoMap',
  },
  pc: {
    path: '/pc',
  },
  signal: {
    path: '/signal',
  },
  mapTest: {
    path: '/mapTest',
  },
  landMap: {
    path: '/landMap',
  },
  auction: {
    path: '/auction',
  },
  auctionMobile: {
    path: '/auctionMobile/[id]',
  },
  auctionPc: {
    path: '/auctionNewWindow',
  },
  auctionMap: {
    path: '/auction/map',
  },
  auctionSearch: {
    path: '/auction/search',
  },
  auctionTotalSearch: {
    path: '/auction/totalSearch',
  },
  auctionStatistics: {
    path: '/auction/statistics',
  },
  auctionRecommendation: {
    path: '/auctionRecommendation/[id]',
  },
  home: {
    path: 'realty/home',
  },
  allMenu: {
    path: '/allMenu',
  },
  analytics: {
    path: 'realty/analytics',
  },
  ranking: {
    path: 'realty/analytics/ranking',
  },
  expert: {
    path: 'realty/analytics/expert',
  },
  post: {
    path: 'post/:postId',
  },
  map: {
    path: 'realty/richgoMap',
  },
  parcel: {
    path: 'realty/parcel/[id]',
  },
  checklistV2Home: {
    path: '/checklist/home',
  },
  checklistV2: {
    path: '/checklist/report/[reportId]',
  },
  checklistCompare: {
    path: '/checklist/report/compare',
  },
  checklist: {
    path: 'realty/checklist',
  },
  checklistTimeMachine: {
    path: 'realty/checklist/time-machine',
  },
  tossWidget: {
    path: 'toss-payments/widget/:productId',
  },
  tossWidgetAuction: {
    path: 'toss-payments/widget/auction/:productId',
  },

  tossWidgetFail: {
    path: 'toss-payments/widget/fail',
  },
  tossWidgetIros: {
    path: 'toss-payments/widget/iros/:productId',
  },
  tossWidgetIrosFail: {
    path: 'toss-payments/widget/iros/fail',
  },
  tossWidgetSafetyJeonse: {
    path: 'toss-payments/widget/safetyJeonse/:productId',
  },
  // 등기부 상세
  registryDetail: {
    path: 'registry/:productId',
  },
  // 등기 변동 알림
  registryAlarmLanding: {
    path: 'registry/alarm/landing',
  },
  purchaseCheck: {
    path: 'purchase/check',
  },
  purchaseSuccess: {
    path: 'purchase/success',
  },
  purchaseFail: {
    path: 'purchase/fail',
  },
  brokerPassPurchase: {
    path: 'purchase/broker-pass',
  },
  checklistPassPurchase: {
    path: 'purchase/checklist-pass',
  },
  partnersPassPurchase: {
    path: 'purchase/partners-pass',
  },
  auctionPassPurchase: {
    path: 'purchase/auction-pass',
  },
  pass: {
    path: 'purchase/pass',
  },
  parcelHome: {
    path: 'realty/parcel/home',
  },
  myHouseAdd: {
    path: 'realty/my-house/add',
  },
  myHouseDetail: {
    path: 'realty/my-house/:id',
  },
  myHouseEdit: {
    path: 'realty/my-house/edit/:id',
  },
  myHouses: {
    path: 'realty/my-house/myHouses',
  },
  my: {
    path: 'my',
  },
  myFavorite: {
    path: 'my/favorite',
  },
  myProfile: {
    path: 'my/profile',
  },
  myProfileEmail: {
    path: 'my/profile/email',
  },
  // 내 서류함
  myDocumentBox: {
    path: 'my/documentBox',
  },
  comment: {
    path: 'comment/:commentId',
  },
  chat: {
    path: 'realty/chat',
  },
  chatPolicy: {
    path: 'realty/chat/policy',
  },
  businessInformation: {
    path: 'business-information',
  },
  ////////////////////////////
  search: {
    path: 'realty/search',
  },

  searchAPT: {
    path: 'searchApt',
  },
  searchByDistance: {
    path: 'searchByDistance',
  },
  searchByEdu: {
    path: 'searchByEdu',
  },
  searchByGap: {
    path: 'searchByGap',
  },
  searchByPublish: {
    path: 'searchByPublish',
  },
  searchByRe: {
    path: 'searchByRe',
  },
  danji: {
    path: 'realty/danji/:id',
  },
  danjiHistory: {
    path: 'realty/danji/:id/history',
  },
  todayRealty: {
    path: 'todayRealty',
  },
  todayRealtyOpenGoods: {
    path: 'todayRealty/opengoods/:id',
  },
  danjiRealTradeHistory: {
    path: 'realty/danji/:id/real-trade-history',
  },
  danjiMinOfferHistory: {
    path: 'realty/danji/:id/min-offer-history',
  },
  officetel: {
    path: 'realty/officetel/:id',
  },
  region: {
    path: 'realty/region/:id',
  },
  elementary: {
    path: 'realty/place/elementary/:id',
  },
  middleSchool: {
    path: 'realty/place/middle/:id',
  },
  middle: {
    path: 'realty/place/middle/:id',
  },
  high: {
    path: 'realty/place/high/:id',
  },
  subwayLine: {
    path: 'realty/place/subwayLine/:id',
  },
  subwayStation: {
    path: 'realty/place/subwayStation/:id',
  },
  redevelop: {
    path: 'realty/place/redevelop/:id',
  },
  rebuilding: {
    path: 'realty/place/rebuilding/:id',
  },
  newConstruction: {
    path: 'realty/place/newConstruction/:id',
  },
  jigu: {
    path: 'realty/place/jigu/:id',
  },
  road: {
    path: 'realty/place/road/:id',
  },
  remodeling: {
    path: 'realty/place/remodeling/:id',
  },
  guessRealTransactionPriceRanking: {
    path: 'realty/guessNextTransactionPrice/ranking',
  },
  ////////////////
  samsungInvalidAuth: {
    path: 'samsung/invalid-auth',
  },

  ////////////////

  sellingDetail: {
    path: 'selling/sellingDetail/:id',
  },
  ////////////////
  notificationHome: {
    path: 'realty/notification/home',
  },
  richgoNow: {
    path: 'realty/notification/richgo-richgoNow/:id',
  },
  notFound: {
    path: '404',
  },
};
const popups = {
  popupDanjiDetail: {
    Comp: dynamic(() => import('../screens/DanjiDetailScreen/PopupDanjiDetail'), {
      ssr: false,
    }),
  },
  popupOfficetelDetail: {
    Comp: dynamic(() => import('../screens/OfficetelDetailScreen/PopupOfficetelDetail'), {
      ssr: false,
    }),
  },
  popupParcelDetail: {
    Comp: dynamic(() => import('../screens/ParcelDetailScreen/PopupParcelDetail'), {
      ssr: false,
    }),
  },
  popupRegionDetail: {
    Comp: dynamic(() => import('../pages/realty/region/[id]/PopupRegionDetail'), {
      ssr: false,
    }),
  },
  popupElementarySchoolDetail: {
    Comp: dynamic(
      () => import('../screens/ElementarySchoolDetailScreen/PopupElementarySchoolDetail'),
      {
        ssr: false,
      }
    ),
  },
  popupMiddleSchoolDetail: {
    Comp: dynamic(() => import('../screens/MiddleSchoolDetailScreen/PopupMiddleSchoolDetail'), {
      ssr: false,
    }),
  },
  popupHighSchoolDetail: {
    Comp: dynamic(() => import('../screens/HighSchoolDetailScreen/PopupHighSchoolDetail'), {
      ssr: false,
    }),
  },

  popupSelectPyeong: {
    Comp: dynamic(() => import('../screens/DanjiDetailScreen/SelectPyeongScreen'), {
      ssr: false,
    }),
  },
  popupSelectFavoriteDanji: {
    Comp: dynamic(() => import('../comp/Popups/PopupSelectFavoriteDanji'), {
      ssr: false,
    }),
  },
  popupShareSelect: {
    Comp: dynamic(() => import('../pages/realty/my-house/register/PopupShareRate'), {
      ssr: false,
    }),
  },
  popupShareInput: {
    Comp: dynamic(() => import('../pages/realty/my-house/register/PopupShareInput'), {
      ssr: false,
    }),
  },
  popupSimpleSelect: {
    Comp: dynamic(() => import('../comp/PopupSimpleSelect'), {
      ssr: false,
    }),
  },
  popupCustomResidenceScore: {
    Comp: dynamic(
      () =>
        import('../screens/DanjiDetailScreen/DanjiInfo/DanjiEnvironment/PopupCustomResidenceScore'),
      {
        ssr: false,
      }
    ),
  },
  popupSelectDistance: {
    Comp: dynamic(
      () => import('../screens/DanjiDetailScreen/DanjiInfo/DanjiEnvironment/PopupSelectDistance'),
      {
        ssr: false,
      }
    ),
  },
  popupAddMyHouse: {
    Comp: dynamic(() => import('../pages/realty/my-house/add/PopupAddMyHouse'), {
      ssr: false,
    }),
  },
  popupRegisterMyHouse: {
    Comp: dynamic(() => import('../pages/realty/my-house/register'), {
      ssr: false,
    }),
  },
  popupEditMyHouse: {
    Comp: dynamic(() => import('../pages/realty/my-house/edit/PopupEditMyHouse'), {
      ssr: false,
    }),
  },
  popupMyHouseDetail: {
    Comp: dynamic(() => import('../pages/realty/my-house/[id]/PopupMyHouseDetail'), {
      ssr: false,
    }),
  },
  popupNoticeDetail: {
    Comp: dynamic(() => import('../pages/realty/notice/PopupNoticeDetail'), {
      ssr: false,
    }),
  },
  popupRegionChartConfig: {
    Comp: dynamic(() => import('../pages/realty/region/[id]/PopupRegionChartConfig'), {
      ssr: false,
    }),
  },
  popupFavoriteDanji: {
    Comp: dynamic(
      () =>
        import(
          '../screens/DanjiDetailScreen/DanjiInfo/DanjiPrice/DanjiPriceFuture/PopupFavoriteDanji'
        ),
      {
        ssr: false,
      }
    ),
  },

  // MyPagePopup
  popupMyHouses: {
    Comp: dynamic(() => import('../pages/realty/my-house/MyHouses/PopupMyHouses'), {
      ssr: false,
    }),
  },
  popupRankingSelect: {
    Comp: dynamic(() => import('../pages/realty/analytics/Ranking/PopupRankingSelect'), {
      ssr: false,
    }),
  },
  popupSelectDirect: {
    Comp: dynamic(() => import('../pages/realty/analytics/Ranking/PopupSelectDirect'), {
      ssr: false,
    }),
  },
  // Expert
  popupSelectIndex: {
    Comp: dynamic(() => import('../pages/realty/analytics/Expert/PopupSelectIndex'), {
      ssr: false,
    }),
  },
  popupSelectPeriod: {
    Comp: dynamic(() => import('../pages/realty/analytics/Expert/PopupSelectPeriod'), {
      ssr: false,
    }),
  },
};
const dialogs = {
  dialog: {
    Comp: dynamic(() => import('../../coreSrc/base/systemManager/dialogManager/DialogContainer'), {
      ssr: false,
    }),
  },
};
type Routing = {
  pages: Record<keyof typeof pages, Route>;
  popups: Record<keyof typeof popups, PopupRoute>;
  dialogs: Record<keyof typeof dialogs, PopupRoute>;
};
const routing: Routing = {
  pages,
  popups,
  dialogs,
};
export default routing;
