import { KAKAO_JAVASCRIPT, RICHGO_API_HOST, isDev, isProduction } from 'coreSrc/core/js/env';
import PopupPermissionDeniedError from 'coreSrc/core/js/error/PopupPermissionDeniedError';
import documentCookie from 'coreSrc/core/js/utils/documentCookie';

import fcmTokenManager from 'jhSrc/utils/fcmTokenManager';

import { RICHGO_API_ACCESS_TOKEN_KEY, RICHGO_API_REFRESH_TOKEN_KEY } from './const';

declare const Kakao: any;
function getKakaoChannelId() {
  if (isProduction) return '_nxfspT';
  else if (isDev) return '_xchDWG';
  else return '_AZDWG';
}

function login() {
  return kakaoLogin();
}

async function kakaoLogin() {
  const richgoApiTokens = await getRichgoApiToken('kakao');
  updateAuthTokenInCookie(richgoApiTokens);
  return richgoApiTokens;
}

// 카카오 추가인증 Oauth 핸드쉐이킹은 웹앱SDK 사용하려면 앱을 새로 배포해야 되서 리디렉트로 함
// 근데 또 팝업방식은 웹앱에서 부모자식 상태공유가 안된다고 해서 어쩔 수 없이 순수 리다이렉트로....
// 나중에 유틸화 하려면 /oauth/kakao/agreements로 다시 보내서 컨텍스트로 어떻게 해보던가....
function kakaoAgreements(scope: string) {
  const origin = new URL(location.href).origin;
  window.location.href = `${origin}/oauth/kakao/agreements?scope=${scope}`;
}

async function appleLogin() {
  const richgoApiTokens = await getRichgoApiToken('apple');
  updateAuthTokenInCookie(richgoApiTokens);
  return richgoApiTokens;
}

const authUtils = {
  oauth: {
    login,
    kakaoLogin,
    kakaoAgreements,
    appleLogin,
  },
};

export default authUtils;

export interface IRichgoApiTokens {
  accessToken: string;
  refreshToken: string;
}

export function updateAuthTokenInCookie(richgoApiTokens: IRichgoApiTokens) {
  documentCookie.set(RICHGO_API_ACCESS_TOKEN_KEY, richgoApiTokens.accessToken);
  documentCookie.set(RICHGO_API_REFRESH_TOKEN_KEY, richgoApiTokens.refreshToken);
  fcmTokenManager.login();
}

export const RICHGO_LOGIN_SUCCESS_EVENT = 'richgoLoginSuccess';
export const RICHGO_LOGIN_FAIL_EVENT = 'richgoLoginFail';

export class RichgoLoginError extends Error {
  tag = 'NONE' as 'UNREGISTED_USER' | 'NONE';
  name = 'LoginFail';
  message = '로그인을 실패했습니다';
  token = '';

  constructor(tag?: RichgoLoginError['tag']) {
    super();

    this.tag = tag;
  }
}

export async function getRichgoApiToken(provider: 'kakao' | 'apple') {
  if (provider === 'apple') {
    if (globalThis.richgoWebview) return getRichgoApiTokenFromRichgoAppApple();
    const origin = new URL(location.href).origin;
    return getRichgoApiTokenFromPopup(`${origin}/oauth/apple`);
  }

  if (provider === 'kakao') {
    if (globalThis.richgoWebview) return getRichgoApiTokenFromRichgoAppKakao();
    const origin = new URL(location.href).origin;
    return getRichgoApiTokenFromPopup(`${origin}/oauth/kakao`);
  }

  throw new RichgoLoginError();
}

async function getRichgoApiTokenFromRichgoAppApple() {
  const loginPromise = new Promise<Record<string, string>>((resolve) => {
    globalThis.richgoWebview.addEventListener('message', (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data.type === 'apple-login-result' && data.from === 'richgo-app') {
          resolve(data);
        }
      } catch (error) {
        return;
      }
    });
  });

  globalThis.richgoWebview.postMessage({ from: 'richgo-web', to: 'richgo-app', type: 'apple-login' });

  const data = await loginPromise;

  if (data.error) {
    throw new RichgoLoginError();
  }

  return { refreshToken: data.refreshToken, accessToken: data.accessToken };
}

async function getRichgoApiTokenFromRichgoAppKakao() {
  const kakaoAccessTokenPromise = new Promise((resolve) => {
    globalThis.richgoWebview.addEventListener('message', (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data.type === 'kakao-login' && data.from === 'richgo-app') {
          resolve(data.kakaoAccessToken);
        }
      } catch (error) {
        return;
      }
    });
  });

  globalThis.richgoWebview.postMessage({ from: 'richgo-web', to: 'richgo-app', type: 'kakao-login' });

  const kakaoAccessToken = await kakaoAccessTokenPromise;
  const response = await fetch(`${RICHGO_API_HOST}/api/oauth/kakao/sync-login/app`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ accessToken: kakaoAccessToken }),
  });

  if (response.status !== 200) throw new RichgoLoginError();
  const json = await response.json();
  const registerToken = json.result.registerToken;
  const refreshToken = json.result.refreshToken;

  if (registerToken) {
    const richgoLoginError = new RichgoLoginError('UNREGISTED_USER');
    richgoLoginError.token = registerToken;
    throw richgoLoginError;
  }

  const kakaoChannelJoined = json.result.kakaoChannelJoined;
  if (!kakaoChannelJoined) {
    if (!Kakao.isInitialized()) {
      await Kakao.init(KAKAO_JAVASCRIPT);
    }
    Kakao.Channel.addChannel({
      channelPublicId: getKakaoChannelId(),
    });
  }

  const accessTokenResponse = await fetch(`${RICHGO_API_HOST}/user/V2/access-token`, {
    headers: { Authorization: `Bearer ${json.result.refreshToken}` },
  });
  if (accessTokenResponse.status !== 200) throw new RichgoLoginError();
  const accessTokenJson = await accessTokenResponse.json();
  const accessToken = accessTokenJson.result.accessToken;

  return { refreshToken, accessToken };
}

async function getRichgoApiTokenFromPopup(url: string): Promise<IRichgoApiTokens> {
  const popupWindow = window.open(url, '_blank');

  if (!popupWindow) throw new PopupPermissionDeniedError();

  return new Promise((resolve, reject) => {
    function onLoginSuccessEvent(customEvent: CustomEvent<IRichgoApiTokens>) {
      window.removeEventListener(RICHGO_LOGIN_SUCCESS_EVENT, onLoginSuccessEvent);
      window.removeEventListener(RICHGO_LOGIN_FAIL_EVENT, onLoginFailEvent);
      resolve(customEvent.detail);
    }

    function onLoginFailEvent(customEvent: CustomEvent<{ error: RichgoLoginError }>) {
      window.removeEventListener(RICHGO_LOGIN_SUCCESS_EVENT, onLoginSuccessEvent);
      window.removeEventListener(RICHGO_LOGIN_FAIL_EVENT, onLoginFailEvent);
      reject(customEvent.detail.error);
    }

    window.addEventListener(RICHGO_LOGIN_SUCCESS_EVENT, onLoginSuccessEvent);
    window.addEventListener(RICHGO_LOGIN_FAIL_EVENT, onLoginFailEvent);
  });
}
