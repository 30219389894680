import { useEffect, useLayoutEffect } from 'react';

import useSystemReducer from 'coreSrc/base/systemManager/useSystemReducer';

import RnManager from '../utils/RnManager';

const useIsomorphicLayoutEffect = globalThis.window ? useLayoutEffect : useEffect;
interface IUserProfile {
  userId: string;
  email: string;
  nickName: string;
  deviceId: string;
}
export default function useWebviewRedirect(
  system: ReturnType<typeof useSystemReducer>,
  userProfile: IUserProfile
) {
  // const logToServer = (content) => {
  //   // const url = 'https://1oqigotuwf.execute-api.ap-northeast-2.amazonaws.com/front-log';
  //   // fetch(url, {
  //   //   method: 'POST',
  //   //   headers: { 'Content-Type': 'application/json' },
  //   //   body: JSON.stringify({ ...content, userProfile }),
  //   // });
  //   const netModelSlack = new NetModel_Slack();
  //   netModelSlack.createNotification('LOG_WEBVIEW_REDIRECT', {
  //     ...content,
  //     env: APP_ENV,
  //     userProfile: JSON.stringify(userProfile),
  //     message: JSON.stringify(content),
  //   });
  // };
  useIsomorphicLayoutEffect(() => {
    if (globalThis.webview) {
      globalThis.webview.addMessageListener((e) => {
        let message;
        try {
          message = JSON.parse(e.data);
          if (message.origin !== 'richgo-webview') {
            throw new Error('웹뷰 리다이렉트와 상관없는 이벤트입니다.');
          }
          if (message.type !== 'redirect') {
            throw new Error('리다이렉트와 상관없는 메세지 유형입니다.');
          }

          const url = new URL(message.url);
          const asPath = url.href.slice(url.origin.length);
          // logToServer({
          //   host: 'useWebviewRedirect > globalThis.webview.addMessageListener',
          //   isError: false,
          //   url,
          //   asPath,
          //   e_data: e?.data,
          //   error: null,
          // });

          const isExternal = message.url.startsWith('http') && !url.host.includes('m.richgo.ai');

          if (isExternal) {
            system.router.goOut(message.url, { target: 'in-app-browser' });
            return;
          }

          system.router.push(asPath);
        } catch (error) {
          // 웹뷰 리다이렉트와 상관 없는 메세지 이벤트는 무시
          // logToServer({
          //   host: 'useWebviewRedirect > globalThis.webview.addMessageListener',
          //   isError: true,
          //   error,
          //   e_data: e?.data,
          // });
        }
      });
      // 웹뷰가 화면 이동을 할 수 있다는 상태를 RN에게 알려주는 코드
      RnManager.query('readyToRedirect');
    }
  }, []);
}
