const ConstSellingAdmin = {
  BrokerApprovalState: {
    WAIT: { code: 0, label: '심사' },
    APPROVAL: { code: 1, label: '승인' },
    DENY: { code: 2, label: '거부' },
    DELETE: { code: 3, label: '삭제' },
    WITHDRAW: { code: 4, label: '탈퇴' },
    NONE: { code: 99, label: '아무것도 아님' },
  },
};
for (const k in ConstSellingAdmin) {
  const t0 = ConstSellingAdmin[k];
  for (const k2 in t0) {
    const t1 = t0[k2];
    if (!(t1 instanceof Function)) t1['key'] = k2;
  }
}
export default ConstSellingAdmin;
