import richFetch from 'coreSrc/core/js/utils/richFetch';

import PARSER_PRICE from 'hostSrc/js/PARSER_PRICE';
import NOTIFICATION_CATEGORY_LIST from 'hostSrc/pages/realty/notification/notification-category-list';

import NotificationHelper from 'jhSrc/components/Notification/NotificationHelper';
import type { TNotificationSummary } from 'jhSrc/types/notification';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export interface ICreateNotificationDanjiSettingPayload {
  id: string;
  price: {
    meme: { active: boolean };
    jeonwolse: { active: boolean };
    pyeongTypeList: number[];
  };
  story: { active: boolean };
  goodnews: { active: boolean };
}

export const updateNotificationDanjiSetting = async (
  payload: ICreateNotificationDanjiSettingPayload
) => {
  const alarmNameList = [];

  if (payload.price.meme.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('meme').code);
  }

  if (payload.price.jeonwolse.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('jeonwolse').code);
  }

  if (payload.story.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('story').code);
  }

  if (payload.goodnews.active) {
    alarmNameList.push(NotificationHelper.toDanjiSettingTopic('goodnews').code);
  }

  const body = {
    targetId: payload.id,
    alarmNameList,
    pyeongTypeList: payload.price.pyeongTypeList ?? [],
  };
  const response = await richFetch('/user/alarm-danji', { method: 'POST', body });
  if (response.status !== 200) throw response;
};

export interface ICreateNotificationParcelSettingPayload {
  id: string;
  schedule: { active: boolean };
  story: { active: boolean };
  goodnews: { active: boolean };
}

export const updateNotificationParcelSetting = async (
  payload: ICreateNotificationParcelSettingPayload
) => {
  const alarmNameList = [];

  if (payload.schedule.active) {
    alarmNameList.push(NotificationHelper.toParcelSettingTopic('schedule').code);
  }

  if (payload.story.active) {
    alarmNameList.push(NotificationHelper.toParcelSettingTopic('story').code);
  }

  if (payload.goodnews.active) {
    alarmNameList.push(NotificationHelper.toParcelSettingTopic('goodnews').code);
  }

  const body = {
    targetId: payload.id,
    favoriteCode: 'PARCEL',
    alarmNameList,
  };
  const response = await richFetch('/user/alarm', { method: 'POST', body });
  if (response.status !== 200) throw response;
};

const CATEGORY = {
  real_transaction: 'real-trade',
};

interface IReadNotificationListPayload {
  deviceId?: string;
}

export const readNotificationList = async (payload: IReadNotificationListPayload) => {
  let urlSearchParams = [['deviceId', payload.deviceId]]
    .filter(([, value]) => (value ?? null) !== null)
    .map((entry) => entry.join('='))
    .join('&');
  urlSearchParams &&= '?' + urlSearchParams;

  const response = await richFetch('/user/notification/v2' + urlSearchParams);
  const json = await response.json();

  const lastViewdTime = new Date(json.result.last_date).getTime();
  json.result.articles?.map((v) => (v.isCustom = false));
  json.result.devices?.map((v) => (v.isCustom = true));
  json.result.users?.map((v) => (v.isCustom = true));

  json.result = [
    ...(json.result.articles ?? []),
    ...(json.result.devices ?? []),
    ...(json.result.users ?? []),
  ];

  json.result.sort((a, b) => {
    return new Date(b.updatedat).getTime() - new Date(a.updatedat).getTime();
  });

  const notificationList: TNotificationSummary[] = (json.result ?? [])
    .filter((v) => {
      const category = NOTIFICATION_CATEGORY_LIST.find((c) => c._id === v.category_id)?.id;

      switch (category) {
        case 'real-trade':
          return true;
        case 'richgo-richgoNow':
          return true;
        case 'custom-parcel':
          return true;
        case 'notice':
          return true;
        case 'weekly_hoga':
          return true;
        case 'update_history':
          return true;
        case 'checklist':
          return true;
        case 'registry':
          return true;
        case 'auction':
          return true;
        default:
          return false;
      }
    })
    .map((v) => {
      const notification_category = NOTIFICATION_CATEGORY_LIST.find((c) => c._id === v.category_id);
      const category = notification_category?.id;
      const icon = notification_category?.icon;

      switch (category) {
        case 'checklist': {
          const summary = JSON.parse(v.summary);
          const description = createChecklistDescription(summary);
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            description,
            type: summary.type,
            danjiId: summary.recommand_danji_id ?? summary.danji_id,
            updatedAt: v.updatedat,
          };
        }

        case 'real-trade': {
          const summary = JSON.parse(v.summary);

          type Data = {
            trade_type: string;
            pyeong_type: any;
            deposit: any;
            price: string | number;
            count: string;
            highest_price: number;
            yyyymmdd: string;
            last_price: number;
            last_yyyymmdd: string;
          };
          const data: Data[] = summary.data;

          type TradeData = {
            tradeType: string;
            d: Data;
          };
          const description = Object.entries<TradeData[]>(
            data
              .map((d) => {
                let tradeType = d.trade_type ?? '-';

                if (tradeType === '전월세') {
                  d.deposit ? (tradeType = '월세') : (tradeType = '전세');
                }
                const key = `${tradeType} ${d.pyeong_type ?? '-'}평`;
                return { key, tradeType, d };
              })
              .reduce<Record<string, TradeData[]>>((acc, { key, tradeType, d }) => {
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push({ tradeType, d });
                return acc;
              }, {})
          )
            .map(([key, group]) => {
              const groupDesc = group
                .map(({ tradeType, d }) => {
                  const money = parseInt(d.price ?? d.deposit);
                  const price =
                    tradeType === '월세'
                      ? `${PARSER_PRICE.getMoneyText(d.deposit, 2).format} / ${
                          PARSER_PRICE.getMoneyText(money, 2).format
                        }`
                      : `${PARSER_PRICE.getMoneyText(money, 2).format}`;

                  // count 및 highest_price는 신규 데이터에서 제거되나, 기존 데이터 호환성을 위해 예외처리 해서 남겨둠
                  const count = parseInt(d.count);
                  const additionalCount = count ? `외 ${count}건` : '';

                  let desc = ' • ';
                  desc += [price, additionalCount].filter((v) => (v ? true : false)).join(' ');

                  if (tradeType !== '월세' && d.highest_price) {
                    const diff = (+d.price / +d.highest_price - 1) * 100;
                    desc += ` (최고가 대비 ${Math.abs(diff).toFixed(1)}% ${diff >= 0 ? '▲' : '▼'})`;
                  }

                  // 여기서부터 신규 추가 데이터.
                  const contractDate = dayjs(d.yyyymmdd, 'YYYY-MM-DD', true);
                  desc += contractDate.isValid()
                    ? ` (계약일 ${contractDate.format('YY.MM.DD')})`
                    : '';

                  if (tradeType !== '월세' && d.last_price && d.last_yyyymmdd) {
                    const lastPrice = `${PARSER_PRICE.getMoneyText(d.last_price, 2).format}`;

                    const lastContractDate = dayjs(d.last_yyyymmdd, 'YYYY-MM-DD', true);
                    const lastContractDateStr = lastContractDate.isValid()
                      ? lastContractDate.format('YY.MM.DD')
                      : '-';

                    const diff = (+d.price / +d.last_price - 1) * 100;
                    desc += `\n    ※ 직전거래 대비 ${Math.abs(diff).toFixed(1)}% ${
                      diff >= 0 ? '▲' : '▼'
                    } (${lastPrice}, ${lastContractDateStr})`;
                  }

                  return desc;
                })
                .join('\n');

              return `${key}\n${groupDesc}`;
            })
            .sort()
            .join('\n\n');

          return {
            category,
            icon,
            realtyType: summary.realty_type,
            danji: { id: summary.danji_id ?? '' },
            title: `${v.title} 실거래가` ?? '',
            description,
            pyeong: summary.data[0].pyeong_type,
            updatedAt: v.updatedat,
          };
        }

        case 'richgo-richgoNow': {
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            updatedAt: v.updatedat,
            isCustom: v.isCustom,
          };
        }

        case 'update_history': {
          return {
            category,
            icon,
            id: v.row_id,
            title: v.title ?? '',
            updatedAt: v.updatedat,
          };
        }

        case 'custom-parcel': {
          const summary = JSON.parse(v.summary);

          const categoryLabel = `내조건청약 발표! 청약일 ${
            summary.parcel_start_date?.slice(5, 10).replace('-', '.') ?? ''
          }`;

          const title =
            summary.danji + ' ' + [summary.pyeong_type_min, summary.pyeong_type_max].join('~') + '평';

          const price = [summary.apply_max_price_min, summary.apply_max_price_max]
            .filter((v) => v)
            .map((v) => PARSER_PRICE.getMoneyText(v).format)
            .join('~');
          const relativePrice = (() => {
            if (summary.plus_price_by_around > 0)
              return (
                ' 시세대비' + PARSER_PRICE.getMoneyText(summary.plus_price_by_around).format + ' 저렴'
              );
            if (summary.plus_price_by_around < 0)
              return (
                ' 시세대비' +
                PARSER_PRICE.getMoneyText(Math.abs(summary.plus_price_by_around)).format +
                ' 비쌈'
              );
            return '';
          })();
          const description = '분양가 ' + price + ' /' + relativePrice;

          return {
            category,
            categoryLabel,
            icon,
            id: summary.danji_id ?? '',
            title,
            description,
            updatedAt: v.updatedat,
          };
        }

        case 'notice': {
          const summary: {
            danjiId: string;
            danjiName: string;
            data: string;
            type: number;
            url?: string;
            subcategory?: string;
          } = JSON.parse(v.summary);

          const noticeType = {
            1: 'opengoods',
            2: 'quickgoods',
            3: 'offer',
            4: 'error',
            5: 'raw',
            6: 'markdown',
          }[summary.type || 4];

          const mapper = {
            opengoods: {
              title: `${summary.danjiName} 주변 안팔린 매물량은?!`,
              description: `${summary.danjiName} 주변 안팔린 매물량이 얼마나 쌓이고 있을까요?`,
            },
            quickgoods: {
              title: `${summary.danjiName} 주변 급매물 확인하기`,
              description: '10%이상 저렴하게 나온 급매물을 확인하세요',
            },
            offer: {
              title: `${summary.danjiName} 주변 호가가 어떻게 변하고 있을까?`,
              description: `${summary.danjiName} 주변 호가 흐름을 확인하세요`,
            },
            error: {
              title: '이 동네 올라',
              description: '알림을 표시할 수 없습니다.',
            },
            raw: {
              title: v.title,
              description: summary.data,
            },
            markdown: {
              title: v.title,
              description: summary.data,
            },
          }[noticeType];

          return {
            category,
            icon,
            id: summary.danjiId ?? '',
            title: mapper.title,
            updatedAt: v.updatedat,
            description: mapper.description,
            noticeType: noticeType,
            danjiName: summary.danjiName,
            url: summary.url,
            subcategory: summary.subcategory,
          };
        }

        case 'weekly_hoga': {
          try {
            const summary = JSON.parse(v.summary);
            let description = '';

            summary.items.forEach((d, i, g) => {
              const tradeType = {
                jeonse: '전세',
                JEONSE: '전세',
                meme: '매매',
                MEME: '매매',
              }[d.type];

              const pyeongType = `${d.size ?? '-'}평`;

              const rateText = d.price_rate
                ? `(3개월 전 대비 ${d.price_rate > 0 ? '🔺 ' : '▼ '}${Math.abs(d.price_rate)}%)`
                : '';

              const money = parseInt(d.price ?? d.deposit);
              const price = `${PARSER_PRICE.getMoneyText(money).format}`;

              const count = parseInt(d.count);
              const additionalCount = count ? ` 외 ${count}건` : '';
              const ln = g.length - 1 !== i ? '\n' : '';
              description +=
                [tradeType, pyeongType, price, additionalCount, rateText]
                  .filter((v) => (v ? true : false))
                  .join(' ') + ln;
            });

            return {
              category,
              icon,
              title: `${v.title}`,
              realtyType: summary.realty_type,
              danji: { id: summary.danji_id ?? '' },
              pyeong: summary.items[0]?.size,
              description,
              updatedAt: v.updatedat,
            };
          } catch (err) {
            console.error(err, v);
            return {
              category,
              icon,
              title: '',
              danji: '',
              description: '',
              updateAt: v.updatedat,
            };
          }
        }

        case 'registry': {
          const summary = JSON.parse(v.summary);

          const newData = {
            description: null,
            data: null,
          };

          /* NOTE: kunhee.lim
           *  registry 는 현재 등기변동알림만 해당되는데 다른 타입이 추가되면
           *  분기쳐야함 */
          if (summary.type === 'DIFF_NOTIFICATION') {
            newData.description = createRegistryAlarmDescription(summary);
            newData.data = summary;
          }

          return {
            category,
            icon,
            id: v.row_id,
            type: newData.data?.type,
            title: v.title || '',
            description: newData.description,
            updatedAt: v.updatedat,
            data: newData.data,
          };
        }

        case 'auction': {
          const { type, data } = JSON.parse(v.summary);

          return {
            category,
            icon,
            id: v.row_id,
            title: v.title || '',
            type,
            data,
            updatedAt: v.updatedat,
          };
        }
      }

      return {
        category: CATEGORY[v.category_id],
        title: v.title ?? '',
        description: v.title ?? '',
      };
    });

  return {
    lastViewdTime,
    items: notificationList,
  };
};

export const readNotificationSettingList = async () => {
  const response = await richFetch('/api/user/user-push-list');
  const json = await response.json();

  const notificationSettingList = (json.result ?? [])
    .filter((setting) =>
      NOTIFICATION_CATEGORY_LIST.find((category) => category._id === setting.pushCategory)
        ? true
        : false
    )
    .map((setting) => {
      const category = NOTIFICATION_CATEGORY_LIST.find(
        (category) => category._id === setting.pushCategory
      );
      return {
        id: category.id,
        title: category.name,
        icon: category.icon,
        active: setting.isOn,
      };
    });

  return notificationSettingList;
};

interface IUpdateNotificationSettingListPayload {
  id: string;
  active: boolean;
}

export const updateNotificationSetting = async (payload: IUpdateNotificationSettingListPayload) => {
  const category = NOTIFICATION_CATEGORY_LIST.find((category) => category.id === payload.id);
  if (!category) return;

  const body = {
    pushCategory: category._id,
    isOn: payload.active,
  };

  const response = await richFetch('/api/user/user-push', {
    method: 'POST',
    body,
  });

  if (response.status !== 200) throw response;
};

function createChecklistDescription(summary: any) {
  if (summary.type1) {
    return `\n최근 확인하신 ${summary.danji}의 지표는\n매우 유의 ${summary.type1}, 유의 ${summary.type2}, 보통 ${summary.type3}, 좋음 ${summary.type4}, 매우 좋음 ${summary.type5}\n${summary.sd} ${summary.sgg}에서는 ${summary.recommand_danji}도 주목해보세요!`;
  } else if (summary.first_up_indicator_id || summary.down_up_indicator_id) {
    const name = summary.danji;

    const upMain = summary.first_up_indicator_id;
    const upCount = summary.up_count;
    const downMain = summary.down_up_indicator_id;
    const downCount = summary.down_count;

    if (!downMain) {
      return `${name} ${upMain}${upCount > 1 ? `외 ${upCount - 1}가지 ` : ''} 점수가 올라갔어요.`;
    } else if (!upMain) {
      return `${name} ${downMain}${upCount > 1 ? `외 ${downCount - 1}가지 ` : ''} 점수가 내려갔어요.`;
    } else {
      return [
        `${name} 지표를 확인해보세요!`,
        upCount > 1
          ? `${upMain} 외 ${upCount - 1}가지 점수가 올라갔어요.`
          : `${upMain} 점수가 올라갔어요.`,
        downCount > 1
          ? `${downMain} 외 ${downCount - 1}가지 점수가 내려갔어요.`
          : `${downMain} 점수가 내려갔어요.`,
      ].join('\n');
    }
  } else {
    return '';
  }
}

function createRegistryAlarmDescription(summary: {
  address: string;
  change: string;
  propertyKey: string;
  registrationNumber: string;
  state: string;
  type: string;
}) {
  return (
    [summary.address, summary.change, summary.state]
      .filter(Boolean)
      .map((item) => `- ${item}`)
      .join('\n') || ''
  );
}
