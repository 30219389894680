// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { APP_ENV } from './src/coreSrc/core/js/env';

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://a0cd466ad5cd4e768e78a07e9de02879@o517156.ingest.sentry.io/5640470',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: APP_ENV,
  enabled: APP_ENV === 'stage' || APP_ENV === 'production', //|| APP_ENV === 'develop' ,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Non-Error exception captured with keys: message, name, statusCode',
    "Can't find variable: IntersectionObserver",
    'window.dispatchEvent(...) is not a function',
    "undefined is not an object (evaluating 'window.$external.web')",
    "Can't find variable: scard",
  ],
});
